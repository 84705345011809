/********** Template CSS **********/

$primary: #AB7442;
$light: #F5F5F5;

.fw-medium {
    font-weight: 500 !important;
}

.fw-bold {
    font-weight: 700 !important;
}

.fw-black {
    font-weight: 900 !important;
}

.back-to-top {
    position: fixed;
    display: none;
    right: 45px;
    bottom: 45px;
    z-index: 99;
}

/*** Spinner ***/
#spinner {
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s ease-out, visibility 0s linear .5s;
    z-index: 99999;
}

#spinner.show {
    transition: opacity .5s ease-out, visibility 0s linear 0s;
    visibility: visible;
    opacity: 1;
}


/*** Navbar ***/
.navbar {
    .dropdown-toggle::after {
        border: none;
        content: "\f107";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        vertical-align: middle;
        margin-left: 8px;
    }

    .nav-item {
        @media (min-width: 992px) {
            .dropdown-menu {
                display: block;
                border: none;
                margin-top: 0;
                top: 150%;
                opacity: 0;
                visibility: hidden;
                transition: .5s;
            }

            &:hover .dropdown-menu {
                top: 100%;
                visibility: visible;
                transition: .5s;
                opacity: 1;
            }
        }
    }
}

.navbar-light {
    .navbar-nav {
        .nav-link {
            margin-right: 30px;
            padding: 25px 0;
            color: #FFFFFF;
            font-size: 15px;
            font-weight: 500;
            text-transform: uppercase;
            outline: none;

            &:hover,
            &.active {
                color: #AB7442;
            }

            @media (max-width: 991.98px) {
                margin-right: 0;
                padding: 10px 0;
            }
        }

        @media (max-width: 991.98px) {
            border-top: 1px solid #EEEEEE;
        }

        .nav-link {
            color: var(--primary);
            font-weight: 500;
        }
    }

    .navbar-brand,
    a.btn {
        height: 75px;
    }

    &.sticky-top {
        top: -100px;
        transition: .5s;
    }
}


/*** Header ***/

@media (max-width: 768px) {
    .header-carousel .owl-carousel-item {
        position: relative;
        min-height: 500px;
    }

    .header-carousel .owl-carousel-item img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .header-carousel .owl-carousel-item h5,
    .header-carousel .owl-carousel-item p {
        font-size: 14px !important;
        font-weight: 400 !important;
    }

    .header-carousel .owl-carousel-item h1 {
        font-size: 30px;
        font-weight: 600;
    }
}

.header-carousel .owl-nav {
    position: absolute;
    width: 200px;
    height: 45px;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
}

.header-carousel .owl-nav .owl-prev,
.header-carousel .owl-nav .owl-next {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    background: transparent;
    border: 1px solid #FFFFFF;
    font-size: 22px;
    transition: .5s;
}

.header-carousel .owl-nav .owl-prev:hover,
.header-carousel .owl-nav .owl-next:hover {
    background: var(--primary);
    border-color: var(--primary);
}

.header-carousel .owl-dots {
    position: absolute;
    height: 45px;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-carousel .owl-dot {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    width: 15px;
    height: 15px;
    background: transparent;
    border: 1px solid #FFFFFF;
    transition: .5s;
}

.header-carousel .owl-dot::after {
    position: absolute;
    content: "";
    width: 5px;
    height: 5px;
    top: 4px;
    left: 4px;
    background: transparent;
    border: 1px solid #FFFFFF;
}

.header-carousel .owl-dot.active {
    background: var(--primary);
    border-color: var(--primary);
}

.page-header {
    background: linear-gradient(rgba(53, 53, 53, .7), rgba(53, 53, 53, .7)), url(../public/slider-1.jpg) center center no-repeat;
    background-size: cover;
}

.breadcrumb-item+.breadcrumb-item::before {
    color: var(--light);
}

/*** Button ***/
.btn {
    font-weight: 500;
    transition: .5s;

    &.btn-primary,
    &.btn-secondary {
        color: #FFFFFF;
    }

    &-square {
        width: 38px;
        height: 38px;

        &.btn-sm-square {
            width: 32px;
            height: 32px;
        }

        &.btn-lg-square {
            width: 48px;
            height: 48px;
        }

        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: normal;
    }
}


/*** Section Title ***/
.section-title h1 {
    position: relative;
    display: inline-block;
    padding: 0 60px;
}

.section-title.text-start h1 {
    padding-left: 0;
}

.section-title h1::before,
.section-title h1::after {
    position: absolute;
    content: "";
    width: 45px;
    height: 5px;
    bottom: 0;
    background: var(--dark);
}

.section-title h1::before {
    left: 0;
}

.section-title h1::after {
    right: 0;
}

.section-title.text-start h1::before {
    display: none;
}

/** About **/
@media (min-width: 992px) {
    .container.about {
        max-width: 100% !important;
    }

    .about-text {
        padding-right: calc(((100% - 960px) / 2) + .75rem);
    }
}

@media (min-width: 1200px) {
    .about-text {
        padding-right: calc(((100% - 1140px) / 2) + .75rem);
    }
}

@media (min-width: 1400px) {
    .about-text {
        padding-right: calc(((100% - 1320px) / 2) + .75rem);
    }
}

/*** Service ***/
.service-item img {
    transition: .5s;
}

.service-item:hover img {
    transform: scale(1.1);
}


/*** Feature ***/
@mixin respond-to($breakpoint) {
    @if $breakpoint == medium {
      @media (min-width: 1200px) {
        @content;
      }
    } @else if $breakpoint == large {
      @media (min-width: 1400px) {
        @content;
      }
    }
  }
  
  // Feature Text Style
  @media (min-width: 992px){
    .container.feature{
        max-width: 100% !important
  }
}
@media (min-width: 992px) and (max-width: 1199px){ // 992px ve 1199px arasındaki ekran boyutları için
    .feature-text{
        padding-left: calc(((100% - 960px) / 2) + .75rem)
    }
}
@media (min-width: 1200px) and (max-width: 1399px){ // 1200px ve 1399px arasındaki ekran boyutları için
    .feature-text{
        
        padding-left: calc(((100% - 1140px) / 2) + .75rem)
    }
}
@media (min-width: 1400px) {// 1400px ve üzeri ekran boyutları için
    .feature-text{
        padding-left: calc(((100% - 1320px) / 2) + .75rem)
    }
}

/*** Project Portfolio ***/
#portfolio-flters li {
    display: inline-block;
    font-weight: 500;
    color: var(--dark);
    cursor: pointer;
    transition: .5s;
    border-bottom: 2px solid transparent;
}

#portfolio-flters li:hover,
#portfolio-flters li.active {
    color: var(--primary);
    border-color: var(--primary);
}

.portfolio-item img {
    transition: .5s;
}

.portfolio-item:hover img {
    transform: scale(1.1);
}

.portfolio-item .portfolio-overlay {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 0;
    height: 0;
    bottom: 0;
    left: 50%;
    background: rgba(53, 53, 53, .7);
    transition: .5s;
}

.portfolio-item:hover .portfolio-overlay {
    width: 100%;
    height: 100%;
    left: 0;
}

.portfolio-item .portfolio-overlay .btn {
    opacity: 0;
}

.portfolio-item:hover .portfolio-overlay .btn {
    opacity: 1;
}


/*** Quote ***/
@media (min-width: 992px) {
    .container.quote {
        max-width: 100% !important;
    }

    .quote-text  {
        padding-right: calc(((100% - 960px) / 2) + .75rem);
    }
}

@media (min-width: 1200px) {
    .quote-text  {
        padding-right: calc(((100% - 1140px) / 2) + .75rem);
    }
}

@media (min-width: 1400px) {
    .quote-text  {
        padding-right: calc(((100% - 1320px) / 2) + .75rem);
    }
}


/*** Team ***/
.team-item img {
    transition: .5s;
}

.team-item:hover img {
    transform: scale(1.1);
}

.team-item .team-social {
    position: absolute;
    width: 38px;
    top: 50%;
    left: -38px;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    transition: .5s;
}

.team-item .team-social .btn {
    color: var(--primary);
    background: #FFFFFF;
}

.team-item .team-social .btn:hover {
    color: #FFFFFF;
    background: var(--primary);
}

.team-item:hover .team-social {
    left: 0;
}


/*** Testimonial ***/
.testimonial-carousel::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    z-index: 1;
}

.testimonial-carousel::after {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    height: 100%;
    width: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    z-index: 1;
}

@media (min-width: 768px) {
    .testimonial-carousel::before,
    .testimonial-carousel::after {
        width: 200px;
    }
}

@media (min-width: 992px) {
    .testimonial-carousel::before,
    .testimonial-carousel::after {
        width: 300px;
    }
}

.testimonial-carousel .owl-item .testimonial-text {
    border: 5px solid var(--light);
    transform: scale(.8);
    transition: .5s;
}

.testimonial-carousel .owl-item.center .testimonial-text {
    transform: scale(1);
}

.testimonial-carousel .owl-nav {
    position: absolute;
    width: 350px;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    opacity: 0;
    transition: .5s;
    z-index: 1;
}

.testimonial-carousel:hover .owl-nav {
    width: 300px;
    opacity: 1;
}

.testimonial-carousel .owl-nav .owl-prev,
.testimonial-carousel .owl-nav .owl-next {
    position: relative;
    color: var(--primary);
    font-size: 45px;
    transition: .5s;
}

.testimonial-carousel .owl-nav .owl-prev:hover,
.testimonial-carousel .owl-nav .owl-next:hover {
    color: var(--dark);
}


/*** Contact ***/
@media (min-width: 992px) {
    .container.contact {
        max-width: 100% !important;
    }

    .contact-text  {
        padding-left: calc(((100% - 960px) / 2) + .75rem);
    }
}

@media (min-width: 1200px) {
    .contact-text  {
        padding-left: calc(((100% - 1140px) / 2) + .75rem);
    }
}

@media (min-width: 1400px) {
    .contact-text  {
        padding-left: calc(((100% - 1320px) / 2) + .75rem);
    }
}


/*** Footer ***/
.footer {
    background: linear-gradient(rgba(53, 53, 53, .7), rgba(53, 53, 53, .7)), url(../public/footer.jpg) center center no-repeat;
    background-size: cover;
}

.footer .btn.btn-social {
    margin-right: 5px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #F5F5F5;
    border: 1px solid #FFFFFF;
    transition: .3s;
}

.footer .btn.btn-social:hover {
    color: #AB7442;
}

.footer .btn.btn-link {
    display: block;
    margin-bottom: 5px;
    padding: 0;
    text-align: left;
    color: #FFFFFF;
    font-size: 15px;
    font-weight: normal;
    text-transform: capitalize;
    transition: .3s;
}

.footer .btn.btn-link::before {
    position: relative;
    content: "\f105";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    margin-right: 10px;
}

.footer .btn.btn-link:hover {
    color: var(--primary);
    letter-spacing: 1px;
    box-shadow: none;
}

.footer .copyright {
    padding: 25px 0;
    font-size: 15px;
    border-top: 1px solid rgba(256, 256, 256, .1);
}

.footer .copyright a {
    color: var(--light);
}

.footer .copyright a:hover {
    color: var(--primary);
}


.testimonial-slider {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img-container {
    position: relative;
    display: inline-block; 
}

.img-container .arrow {
    display: flex; /* Varsayılan olarak gizli */
}
 

.testimonial-item {
    max-width: 600px;
    margin: 0 auto;
}

.arrow {
    color: #AB7442; /* Primary renk */
    border: none;
    padding: 10px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    font-size: 24px; /* İkon boyutu */
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
}

.left-arrow {
    left: -70px; /* Sol oka göre pozisyon - resimden daha uzak */
}

.right-arrow {
    right: -70px; /* Sağ oka göre pozisyon - resimden daha uzak */
}

.arrow:hover {
    color: #AB7442; /* İkon hover rengi */
}


@import '~bootstrap/scss/bootstrap'